<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-08-12 18:15:19
 * @LastEditTime: 2022-01-17 15:49:05
 * @Description: 调价数据统计
 * @Param:
 * @FilePath: \JT_Web_test\src\views\salesBusiness\contractManage\contractPrice\priceAdjustmentStatistics.vue
 -->
<template>
    <div class="priceAdjustmentStatistics" v-loading="loading">
        <ul class="header">
            <li>
                <i class="iconfont icond"></i>
                <div>
                    <h4>{{ statisticsData.task_count || 0 }}</h4>
                    <p>任务(个)</p>
                </div>
            </li>
            <li>
                <i class="iconfont icond"></i>
                <div>
                    <h4>{{ statisticsData.delivery_count || 0 }}</h4>
                    <p>发货单(个)</p>
                </div>
            </li>
            <li>
                <i class="iconfont icond"></i>
                <div>
                    <h4>{{ statisticsData.concrete_volume || 0 }}</h4>
                    <p>总砼方量(方)</p>
                </div>
            </li>
            <li>
                <i class="iconfont icond"></i>
                <div>
                    <h4>{{ statisticsData.subtotal_money || 0 }}</h4>
                    <p>原总金额(元)</p>
                </div>
            </li>
            <li>
                <i class="iconfont icond"></i>
                <div>
                    <h4>{{ statisticsData.current_subtotal_money || 0 }}</h4>
                    <p>现总金额(元)</p>
                </div>
            </li>
            <li>
                <i class="iconfont icond"></i>
                <div>
                    <h4>{{ statisticsData.adjusted_money || 0 }}</h4>
                    <p>本次调价总差额(元)</p>
                </div>
            </li>
            <li class="status">
                <h4>状态：</h4>
                <el-select v-model="status" placeholder="请选择" @change="init">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="砼调整" :value="1"></el-option>
                    <el-option label="砂浆调整" :value="2"></el-option>
                    <el-option label="泵送费调整" :value="3"></el-option>
                    <el-option label="运费调整" :value="4"></el-option>
                    <el-option label="其他调整" :value="5"></el-option>
                </el-select>
            </li>
        </ul>
        <section class="table-bg">
            <el-table
                border
                show-summary
                height="100%"
                ref="statisticsTable"
                :data="tableData"
                style="width: 100%"
                :summary-method="getSummaries"
                :cell-class-name="cellClassName"
            >
                <el-table-column
                    fixed
                    prop="product_markNum_name"
                    label="产品标号"
                    width="120"
                    class-name="productLabel total"
                >
                </el-table-column>
                <el-table-column
                    prop="concrete_volume"
                    label="砼方量"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="concrete_price"
                    label="砼原单价"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_concrete_price"
                    label="砼现单价"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="concrete_amount_money"
                    label="砼原金额"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_concrete_amount_money"
                    label="砼现金额"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="concrete_difference_money"
                    label="砼调价差额"
                    width="100"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.concrete_difference_money }}</span>
                        <i class="iconfont iconjiang-t"></i>
                        <i class="iconfont iconjiang"></i>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    label=""
                    width="4"
                    class-name="borderColumn"
                >
                </el-table-column>
                <el-table-column
                    prop="mortar_volume"
                    label="砂浆方量"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="mortar_price"
                    label="砂浆原单价"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_mortar_price"
                    label="砂浆现单价"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="mortar_amount_money"
                    label="砂浆原金额"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_mortar_amount_money"
                    label="砂浆现金额"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="mortar_difference_money"
                    label="砂浆调价差额"
                    width="120"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.mortar_difference_money }}</span>
                        <i class="iconfont iconjiang-t"></i>
                        <i class="iconfont iconjiang"></i>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    label=""
                    width="4"
                    class-name="borderColumn"
                >
                </el-table-column>
                <el-table-column
                    prop="pump_volume"
                    label="泵送方量"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="pump_price"
                    label="泵送原单价"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_price"
                    label="泵送现单价"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="pump_money"
                    label="泵送原金额"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_pump_money"
                    label="调整后泵送金额"
                    width="140"
                >
                </el-table-column>

                <el-table-column
                    prop="pump_difference_money"
                    label="泵送调价差额"
                    width="120"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.pump_difference_money }}</span>
                        <i class="iconfont iconjiang-t"></i>
                        <i class="iconfont iconjiang"></i>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="address"
                    label=""
                    width="4"
                    class-name="borderColumn"
                >
                </el-table-column>
                <el-table-column
                    prop="deliver_volume"
                    label="运输方量"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="freight_base_price"
                    label="运费原基础单价"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_freight_base_price"
                    label="运费现基础单价"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="than_freight_base_price"
                    label="运费原超运距单价"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_than_freight_base_price"
                    label="运费现超运距单价"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="replenish_quantity"
                    label="运费补方数"
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_replenish_quantity"
                    label="调整后运费补方数"
                    width="140"
                >
                </el-table-column>
                <el-table-column
                    prop="replenish_money"
                    label="运费补方金额"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_replenish_money"
                    label="调整后运费补方金额"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="freight"
                    label="运费"
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    prop="adjusted_freight"
                    label="调整后运费"
                    width="100"
                >
                </el-table-column>

                <el-table-column
                    fixed="right"
                    prop="subtotal"
                    label="小计金额"
                    width="80"
                    class-name="subtotal total"
                >
                </el-table-column>
                <el-table-column
                    fixed="right"
                    prop="adjusted_subtotal"
                    label="调整后小计金额"
                    width="120"
                    class-name="total"
                >
                </el-table-column>
                <el-table-column
                    fixed="right"
                    prop="adjust_status"
                    label="状态"
                    width="80"
                    class-name="total"
                >
                </el-table-column>
            </el-table>
        </section>
    </div>
</template>

<script>
export default {
    name: 'price-adjustment-statistics',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 类型
            status: 0,
            // 统计数据
            statisticsData: {},
            tableData: [],
        };
    },
    computed: {},
    watch: {},
    async created() {
        await this.init();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async init() {
            this.loading = true;
            this.statisticsData = await this.getPageData() || {};
            if (Object.keys(this.statisticsData).length > 0) {
                this.tableData = this.statisticsData.accountsAdjustedPriceProductDtos || [];
                this.$nextTick(() => {
                    this.$refs.statisticsTable.doLayout();
                });
            }
            this.loading = false;
        },
        getPageData() {
            // eslint-disable-next-line max-len
            return this.$axios.$get(`/interfaceApi/sale/accounts/adjusted_price_statistics/${this.extr.letterNumber}/${this.status}`, { defEx: true });
        },

        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;
                    }, 0);
                } else {
                    sums[index] = '——';
                }
            });
            return sums;
        },

        // 单元格类名
        // eslint-disable-next-line complexity
        cellClassName(data) {
            let classNmae = '';
            // eslint-disable-next-line max-len
            const concrete = ['concrete_volume', 'concrete_price', 'adjusted_concrete_price', 'concrete_amount_money', 'adjusted_concrete_amount_money', 'concrete_difference_money'];
            if (concrete.indexOf(data.column.property) > -1) {
                if (data.row.concrete_difference_money > 0) {
                    classNmae = 'raise';
                } else if (data.row.concrete_difference_money < 0) {
                    classNmae = 'down';
                }
                if (data.column.property === 'adjusted_concrete_price') {
                    if (data.row.concrete_price > data.row.adjusted_concrete_price) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.concrete_price < data.row.adjusted_concrete_price) {
                        classNmae = classNmae + ' markUp';
                    }
                }
                if (data.column.property === 'adjusted_concrete_amount_money') {
                    if (data.row.concrete_amount_money > data.row.adjusted_concrete_amount_money) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.concrete_amount_money < data.row.adjusted_concrete_amount_money) {
                        classNmae = classNmae + ' markUp';
                    }
                }
                if (data.column.property === 'concrete_difference_money') {
                    if (data.row.concrete_difference_money < 0) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.concrete_difference_money > 0) {
                        classNmae = classNmae + ' markUp';
                    }
                }
            }

            // eslint-disable-next-line max-len
            const mortar = ['mortar_volume', 'mortar_price', 'adjusted_mortar_price', 'mortar_amount_money', 'adjusted_mortar_amount_money', 'mortar_difference_money'];
            if (mortar.indexOf(data.column.property) > -1) {
                if (data.row.mortar_difference_money > 0) {
                    classNmae = 'raise';
                } else if (data.row.mortar_difference_money < 0) {
                    classNmae = 'down';
                }
                if (data.column.property === 'adjusted_mortar_price') {
                    if (data.row.mortar_price > data.row.adjusted_mortar_price) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.mortar_price < data.row.adjusted_mortar_price) {
                        classNmae = classNmae + ' markUp';
                    }
                }
                if (data.column.property === 'adjusted_mortar_amount_money') {
                    if (data.row.mortar_amount_money > data.row.adjusted_mortar_amount_money) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.mortar_amount_money < data.row.adjusted_mortar_amount_money) {
                        classNmae = classNmae + ' markUp';
                    }
                }
                if (data.column.property === 'mortar_difference_money') {
                    if (data.row.mortar_difference_money < 0) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.mortar_difference_money > 0) {
                        classNmae = classNmae + ' markUp';
                    }
                }
            }

            // eslint-disable-next-line max-len
            const pump = ['pump_volume', 'pump_price', 'adjusted_pump_price', 'pump_money', 'adjusted_pump_money', 'pump_difference_money'];
            if (pump.indexOf(data.column.property) > -1) {
                if (data.row.pump_difference_money > 0) {
                    classNmae = 'raise';
                } else if (data.row.pump_difference_money < 0) {
                    classNmae = 'down';
                }
                if (data.column.property === 'adjusted_pump_price') {
                    if (data.row.pump_price > data.row.adjusted_pump_price) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.pump_price < data.row.adjusted_pump_price) {
                        classNmae = classNmae + ' markUp';
                    }
                }
                if (data.column.property === 'adjusted_pump_money') {
                    if (data.row.pump_money > data.row.adjusted_pump_money) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.pump_money < data.row.adjusted_pump_money) {
                        classNmae = classNmae + ' markUp';
                    }
                }
                if (data.column.property === 'pump_difference_money') {
                    if (data.row.pump_difference_money < 0) {
                        classNmae = classNmae + ' priceReduction';
                    } else if (data.row.pump_difference_money > 0) {
                        classNmae = classNmae + ' markUp';
                    }
                }
            }
            return classNmae;
        },
    },
};
</script>
<style lang="stylus" scoped>
.priceAdjustmentStatistics
    height 8rem
    .header
        height 0.7rem
        padding-left 0.15rem
        display flex
        align-items center
        justify-content space-between
        background-image none
        li
            flex 1
            display flex
            height 0.42rem
            margin-right 0.15rem
            border 1px solid #5588F1
            >i
                width 0.42rem
                background #5588F1
                font-size 0.2rem
                color #fff
                text-align center
                line-height 0.4rem
            >div
                width calc(100% - 0.4rem)
                text-align center
                h4
                    font-size 0.18rem
                    line-height 0.22rem
                    color #5588F1
                    font-weight 600
                p
                    font-size 0.12rem
                    line-height 0.16rem
                    color #666666
            &.status
                flex 1.5
                border none
                h4
                    width 0.6rem
                    font-size 0.16rem
                    color #8593A7
                    line-height 0.42rem
                >>>.el-select
                    .el-input__inner
                        height 0.42rem
                        line-height 0.42rem
            &:nth-of-type(1)
                border 1px solid #5588F1
                >i
                    background #5588F1
                >div
                    background #F9FBFF
                    h4
                        color #5588F1
            &:nth-of-type(2)
                border 1px solid #FA8436
                >i
                    background #FA8436
                >div
                    background #FFFAF7
                    h4
                        color #FA8436
            &:nth-of-type(3)
                border 1px solid #21BDCC
                >i
                    background #21BDCC
                >div
                    background #F5FEFF
                    h4
                        color #21BDCC
            &:nth-of-type(4)
                border 1px solid #FFB729
                >i
                    background #FFB729
                >div
                    background #FFFCF7
                    h4
                        color #FFB729
            &:nth-of-type(5)
                border 1px solid #84CC39
                >i
                    background #84CC39
                >div
                    background #FAFFF5
                    h4
                        color #84CC39
            &:nth-of-type(6)
                border 1px solid #EB656F
                >i
                    background #EB656F
                >div
                    background #FFF2F3
                    h4
                        color #EB656F
    section
        height calc(100% - 0.7rem)
        padding 0 0.15rem 0.15rem 0.15rem
        >>>.el-table
            border 1px solid #D7D7D7
            border-radius 0
            border-top none
            th,td
                text-align center
                padding 0
                height 0.4rem
                line-height 0.4rem
                border-right 1px solid #D7D7D7
                &:last-child
                    border-right none
            th
                border-top 1px solid #D7D7D7
                border-color #D7D7D7
                background-color #EDF0F5
                color #022782
                &.subtotal
                    background #F5F2ED
                    color #823802
                &.borderColumn
                    border-top 3px solid #fff
            .borderColumn
                background #EDF0F6 !important
                border-top none
                border-bottom none
            td
                border-top-color #D7D7D7
                .iconfont
                    display none
                &.productLabel
                    background #F6F8FC
                &.subtotal
                    color #823802
                &.raise
                    background #FFEDED
                &.down
                    background #D7F8F1
                &.markUp
                    color #E02020
                    font-weight 600
                    .iconjiang-t
                        display inline-block
                &.priceReduction
                    color #019E7C
                    font-weight 600
                    .iconjiang
                        display inline-block
            .el-table__footer-wrapper
                td
                    background #FEF9E4
                    color #FF6600
                    font-weight 600
            .el-table__fixed-footer-wrapper
                .total
                    background #FEF9E4
                    color #FF6600
                    font-weight 600
            .el-table__empty-block
                width 100% !important
</style>
